<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <client-only>
          <div class="menu">
            <nuxt-link
              v-for="(item, index) in MENUS"
              :id="`link-${item?.name}`"
              :key="index"
              class="menu__item"
              :class="[
                item?.name,
                {
                  openSubMenu: openSubMenu === item?.tab,
                  active:
                    item?.tab === route?.name ||
                    ((item?.name === 'casino' || item?.name === 'game') &&
                      route?.name?.includes(item?.name) &&
                      ![LOBBY_GAME_URLS.GAME_BAI, LOBBY_GAME_URLS.LO_DE].includes(route.fullPath)) ||
                    item?.activeUrl === route.fullPath
                }
              ]"
              :to="typeSubMenu.includes(item?.type as string) ?
                (item?.subMenu?.[0]?.alias === 'all' ? item?.activeUrl : `${item?.activeUrl}/${item?.subMenu?.[0]?.alias ?? ''}`) :
                item?.activeUrl"
              @mouseover="onMouseOver(item)"
              @mouseleave="onMouseLeave()"
              @click.prevent="onClickMenu"
            >
              <div class="menu-item-text">
                <span class="menu-title">{{ item.title }}</span>
                <span v-if="item.subMenu && item.subMenu.length" class="icon-menu-down" />
              </div>
              <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
                <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 }">
                  <nuxt-link
                    v-for="(subItem, subIndex) in item.subMenu"
                    :id="`header-${subItem?.name}`"
                    :key="`${index}${subIndex}`"
                    class="sub-menu__item"
                    :class="[
                      subItem?.type,
                      {
                        active:
                          subItem.alias === route.params.type ||
                          (subItem.alias === 'all' && !route.params.type && route.path === PAGE_URLS.CASINO)
                      }
                    ]"
                    :to="subItem?.alias === 'all' ? item?.rootActiveUrl : item?.rootActiveUrl + `/${subItem?.alias}`"
                    @click.prevent="onClickMenu"
                  >
                    <div class="sub-menu__item--content">
                      <BaseImg class="icon-active" :src="subItem.icon" :alt="subItem.display_name" />
                      <div class="text">
                        <p>{{ subItem.display_name }}</p>
                        <div v-if="subItem.jackpot && sumJackpot[subItem.jackpot] > 0" class="jackpot">
                          <AnimateNumber class="value" :number="sumJackpot[subItem.jackpot]" />
                          <span>G</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="!subItem?.isNotShowLine" class="line" />
                  </nuxt-link>
                </div>
              </div>
            </nuxt-link>
          </div>
        </client-only>
      </div>
      <div class="header-button">
        <client-only>
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { MENUS } from '~/constants/menu'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import AnimateNumber from '~/components/common/animate-number.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useGameStore } from '~~/store/game'
import { LOBBY_GAME_URLS, LOBBY_CASINO_URLS, PAGE_URLS } from '~/config/page-url'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser, isLogged } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const router = useRouter()
const openSubMenu = ref('')
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    openSubMenu.value = item?.tab
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}
const typeSubMenu = ref(['livecasino', 'games'])
const fetchData = () => {
  fetchCongGame()
  fetchGameCasino()
}

const onClickMenu = () => {
  openSubMenu.value = ''
}

useAsyncData(async () => {
  fetchData()
})

const listLink = reactive(MENUS)

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = listLink.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      const updatedSubmenu = submenu.map((subItem: ISubMenuHeader) => ({
        ...subItem
      }))
      targetItem.subMenu = isLogged.value
        ? updatedSubmenu
        : updatedSubmenu.filter((item: ISubMenuHeader) => item?.alias !== 'recently')
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'games' },
    { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
