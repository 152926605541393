<template>
  <div class="header-user">
    <client-only>
      <div class="user-block">
        <div class="user-menu">
          <div class="user-info">
            <div class="balance">
              <div class="icon-plus" @click="onNavDeposit">
                <BaseImg src="/assets/images/components/common/plus-icon.svg" class="icon" alt="icon plus" />
              </div>
              {{ $formatNumberWithCommas(store.currentUser?.balance, ',') }} K
            </div>
            <div
              class="user-info-detail"
              :class="{ active: isShowMenu }"
              @mouseleave="mouseLeave"
              @mouseover="mouseOver"
            >
              <div class="avatar">
                <BaseImg
                  :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
                  alt="avatar"
                />
              </div>
              <div class="username">{{ truncatedFullName }}</div>
              <span class="icon-menu-down" />
              <div class="menu-dropdown" :class="{ active: isShowMenu }">
                <nuxt-link
                  v-for="(item, index) in USER_MENU"
                  :id="item?.name"
                  :key="index"
                  class="menu-item"
                  :class="{ active: item.activeUrl === route.fullPath }"
                  @click="onClick(item)"
                >
                  <span class="icon" :class="item.img" />
                  <div class="text">{{ item.name }}</div>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { PAGE_URLS } from '@/config/page-url'
import BaseImg from '~/components/common/base-img'
import BaseButton from '~/components/common/base-button'
import { useLogout } from '~/composables/user/useLogout'
import { USER_MENU } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { ACCOUNT_URLS } from '~/config/page-url'
import { IMenuUserDesktop } from '~/types/menu.type'
const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const isShowMenu = ref(false)
const route = useRoute()

const truncatedFullName = computed(() => {
  const fullname = store.currentUser?.fullname || ''
  return fullname.length > 15 ? fullname.substring(0, 12) + '...' : fullname
})
const onClick = (item: IMenuUserDesktop) => {
  isShowMenu.value = false
  if (item.url === ACCOUNT_URLS.DEPOSIT_DA) {
    onNavDeposit()
    return
  }
  if (item.url === ACCOUNT_URLS.WITHDRAW_BANK) {
    onNavWithdraw()
    return
  }
  if (item.isLogout) {
    logout()
  } else {
    useRoute().path !== item.url && navigateTo(item.url)
  }
}

const mouseOver = () => {
  isShowMenu.value = true
}

const mouseLeave = () => {
  isShowMenu.value = false
}
const onNavDeposit = () => {
  if (useRoute().path !== ACCOUNT_URLS.DEPOSIT_DA) {
    navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
  }
}
const onNavWithdraw = () => {
  if (useRoute().path === ACCOUNT_URLS.WITHDRAW_BANK) {
    return
  }
  navigateTo(ACCOUNT_URLS.WITHDRAW_BANK)
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-logged.scss" />
