<template>
  <div class="floating">
    <div v-for="(item, indexSubMenu) in contactList" :key="indexSubMenu" class="floating__item">
      <a :href="item.url" target="_blank" class="floating__item--image">
        <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="item.img" alt="icon" />
        <div class="tooltip">
          <div class="tooltip-content">
            <span class="description">{{ item?.display_name }}</span>
          </div>
          <div class="arrow-down" />
        </div>
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { useContact } from '~/composables/useContact'

const { isBotChromeLighthouse } = useAppStore()
const { contactList, fetchContact } = useContact()

onMounted(() => {
  fetchContact()
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/floating-menu/index.scss" />
